@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

mark {
  background-color: #097CC157;
}

.help-icon {
  color: #097CC1;
  width: 42px;
  height: 42px;
  cursor: pointer;

}

.sui-search-box {
  align-items: center !important;
  flex-direction: row !important;
  
}

@media (max-width:800px) {
  .sui-search-box{
        flex-direction: column !important;
  }
}

.sui-search-box_two {
  margin-left: 8% !important;
  align-items: center !important;
}



.popup-content {
  align-items: center !important;
  width: 50% !important;

  /* padding: 0 !important;
  border: 0 !important;
  background: none !important; */
}

.help-modal h1 {
  color: #334D6E;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14pt;
  width: 100%;
  /* border-bottom: 1px solid gray; */
  text-align: center;
  padding: 5px;
}

.help-modal > .content {
  color: #707683;
  width: 100%;
  padding: 0px 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 9pt;
  line-height: 15px;
  text-align: left;
}

.help-modal > .close {
  background: url('./assets/cerrar_default.png') no-repeat 24px 50%;
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  width: 60px;
  height: 60px;
  border: 0;
}

.help-modal > .close:hover {
  background: url('./assets/cerrar_hover.png') no-repeat 24px 50%;
}


.help-example {
  width: 100%;
  height: auto;
}

.argentina-logo {
  width: auto;
  height: 50px;
}

.App {
  position: relative;
  background: #FFFFFF;
  height: 100%;
  min-height: 60vh;
}


.App:before {
  content:"";
  background:#FFFFFF;
  position: absolute;
  height: 100%;
  width: 200vw;
  left: -100vw;
  z-index: -1;
}

.sui-layout-header {
  background:#FFFFFF !important;
  padding: 10px 0px !important;
}

.sui-layout-header__inner {
  display: flex;
  flex-flow: column nowrap;
}

.sui-layout-main {
  background: inherit !important;
  padding-right: 24px !important;
}

.sui-layout-main-header {
  padding: 0 5px !important;
}

.sui-layout {
  background: inherit !important;
}

.sui-facet-search__text-input, .sui-facet-search__text-input:hover {
  background-color:#F5F6F8 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
  border: 0 !important;
}

input.sui-search-box__text-input {
  background-color:#F5F6F8 !important;
  background: url('https://api.iconify.design/ant-design:search-outlined.svg?color=%23C2CFE0&height=32') no-repeat scroll 14px 50%;
  padding-left:50px;
  box-shadow: 2px 2px rgba(0,0,0,0.2);
  border: 0;
  background-size:25px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
}

.sui-search-box__text-input:focus{
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  border: 0 !important;
}

.sui-layout-body__inner {
  background: inherit !important;
  padding: 0px !important;
}

.sui-layout-body {
  background: inherit !important;
}

.sui-layout-sidebar {
  background:#FFFFFF !important;
  z-index: 1;
  padding: 0 !important;
}

.sidebar-base {
  padding-left: 24px !important;
  -moz-box-shadow:    3px 0 2px -2px rgba(0,0,0,0.2);
  -webkit-box-shadow: 3px 0 2px -2px rgba(0,0,0,0.2);
  box-shadow:         3px 0 2px -2px rgba(0,0,0,0.2);
  padding: 32px 32px 0 0;
}

.sui-layout-body:after {
  background:#FFFFFF !important;
}

.footer {
  margin: 25px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background-color: #097CC1;
  max-width: 100%;
}

.footer:before {
    content:"";
    background: #097CC1;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
    z-index: -1;
}

footer p {
  text-align: left;
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 9pt;
}

footer a {
  color: #FFFFFF;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 9pt;
  text-align: left;
  text-decoration: none;
}

.loader {
  margin-top: 50px;
  background-color: #FFFFFF;
}

.sui-result__title {
  font-size: 1em !important;
  font-weight: 550;
}

.result-root {
  flex-grow: 1;
}

.result-paper {
  padding: 10px;
  margin: 10px 0;
  max-width: 500;
}

.result-image {
  width: 128;
  height: 128;
}

.result-img {
  margin: 'auto';
  display: 'block';
  max-width: '100%';
  max-height: '100%';
}

.MuiIcon-colorPrimary {
  color: #F5F6F8;
}

.sui-facet-view-more:hover {
  background: none !important;
  outline: none !important;

}

.sui-search-box__submit {
  background: #097CC1 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}


.sui-search-box__submit:hover {
  background: #097CC1 !important;
  opacity: 0.9;
  border: 0 !important;
  box-shadow: 2px 2px rgba(0,0,0,0.2) !important;
  font-family: 'Roboto', sans-serif !important;
  font-weight: 500 !important;
  font-style: normal !important;
}


.body-home{
  display: flex;
  padding: 50px;
  flex-flow: column nowrap;
}

.read-more-button {
  cursor: pointer;
}

html, body {margin: 0; padding: 0; overflow-x:hidden;}

.body-help-poup:hover .help-icon {
  color: #52ACE1 !important;
}

.body-help-poup:hover .MuiTypography-root {
  color: #52ACE1 !important;
}

.grecaptcha-badge { 
  visibility: hidden; 
}

@keyframes glow {
  from {
    box-shadow: 0 0 5px -5px #52ACE1;
  }
  to {
    box-shadow: 0 0 5px 5px #52ACE1;
  }
}

.body-home .help-icon {
  color: #097CC1;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 100px;
  animation: glow 1s infinite alternate;
}

.header-base-layout {
  width: 76%;
  align-self: flex-end;
  padding: 0 32px;
}



@media (max-width:800px) {
  .header-base-layout {
    width: 100%;
    padding: 0 0px;
  }
}


@media (max-width:500px) {
  .sui-search-box__wrapper_two {
    width: 120% !important;
  }
}

@media  (min-width: 501px) and (max-width: 800px) {
  .sui-search-box__wrapper_two {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .sui-search-box__wrapper_two + input.sui-search-box__submit {
    margin-top:20px;
    margin-left:0 !important;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
}


@media (min-width:801px) {
  .sui-search-box__wrapper_two {
    width: 66% !important;
  }
}




@media (max-width:800px) {
      .sui-layout-header {
        padding: 0px !important;
      }

      .sui-layout-sidebar {
         display: none !important;
        }

        .sui-layout-main {
          width: 100% !important;
          padding-left: 14px !important;
          padding-right: 14px !important;
          padding-top: 15px !important;
        }

      .sui-search-box__wrapper input.button.sui-search-box__submit 
      {
        
        background: url('https://api.iconify.design/ant-design:search-outlined.svg?color=%23C2CFE0&height=25') no-repeat center 50% !important;
        /*background-color: #F5F6F8 !important;*/
        box-shadow: 0px 0px rgb(0 0 0 / 20%) !important;

      }
      .sui-search-box__wrapper {
        flex-direction: row !important;
        justify-content: space-between !important;
        box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%) !important;
        background-color: #FFFFFF !important;
        max-height: 30px !important;
      }
      

      .body-home .help-icon {
        width: 30px;
        height: 30px;
      }  
      .popup-content {
        width: 90% !important;
      }
      .sui-search-box_two{
        margin-left:0 !important;
      }
            
      .sui-search-box__wrapper  input.sui-search-box__text-input {
        
        background: none !important;
        padding-left:10px;
        box-shadow: 0px 0px rgba(0,0,0,0.2);
        border: 0;
        background-size:25px !important;
        padding: 10px !important;
        /*background-color: !important;*/
      }
      .sui-search-box__wrapper  input.sui-search-box__text-input:focus{
        box-shadow: 0px 0px rgba(0,0,0,0.2) !important;
        border: 0 !important;
      }
      .css-1pcexqc-container sui-select sui-select--inline {
        width: 100px
      }
      .sui-results-container {
        padding-top: 2px !important;
      }
}



@media  (min-width: 600px) and (max-width: 800px) {
  .body-home .help-icon{
    width: 40px;
    height: 40px;
  } 
}

@media  (min-width: 801px) and (max-width: 1200px) {
  .body-home .help-icon {
    width: 45px;
    height: 45px;
  }  
}

@media (max-width:800px) {
  .sui-layout-sidebar-toggle {  
    font-size: 10px !important;
    padding: 5px !important;
    display: none !important;
  }
}


@media  (max-width:800px) {
   .sui-layout-sidebar--toggled {
    height: 600px !important;
    margin: 100px !important;
  }
}

@media (max-width:800px) {
  .sui-layout-main-header__inner {
    justify-content: space-between !important;
    padding-bottom: 10px !important;
  }
}

/*
.MuiPaginationItem-textSecondary.Mui-selected {
    color: transparent !important;
    background-color: transparent !important;
  }

  .MuiPaginationItem-sizeSmall {
    color: transparent !important;
    background-color: transparent !important;
    pointer-events: none !important;
  }

 .MuiPaginationItem-sizeLarge {
    color: transparent !important;
    background-color: transparent !important;
    pointer-events: none !important;
  }

  .MuiPaginationItem-sizeSmall .MuiPaginationItem-icon
  {
    color: #000000de !important;
    pointer-events: initial  !important;
    cursor: pointer  !important;
  }

  .MuiPaginationItem-sizeLarge .MuiPaginationItem-icon
  {
    color: #000000de !important;
    pointer-events: initial  !important;
    cursor: pointer  !important;
  }

*/
  .MuiAccordion-root:before {
    background-color: transparent !important;
  }



  .react-datepicker__input-container input {
    width: 70px !important;
    border-bottom-color: #097CC1 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .react-datepicker {
    display: none !important;
  }

  @media  (max-width:800px) {
    .sui-multi-checkbox-facet__checkbox {
      margin-left: 50px !important;
   }
 }
 @media  (max-width:800px) {
    .MuiIconButton-label {
   margin-left: 20px !important;
 }
 
 }